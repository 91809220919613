import { createRoot } from "react-dom/client";
import Podcasts from "./components/Podcasts";

export const initPodcasts = () => {
  const elPodcasts = document.querySelector<HTMLElement>(".js-podcasts");

  if (!elPodcasts) return;

  const baseUrl = elPodcasts.dataset["baseUrl"];
  const errorMsg = elPodcasts.dataset["errorMsg"];
  const buttonText = elPodcasts.dataset["buttonText"];

  if (!baseUrl || !errorMsg || !buttonText) return;

  const root = createRoot(elPodcasts);
  root.render(
    <Podcasts baseUrl={baseUrl} errorMsg={errorMsg} buttonText={buttonText} />,
  );
};
