import { hideElement, showElement } from "./utils";

export const handleVideoClick = (clickedElement: HTMLElement) => {
  const videoPreview = clickedElement.closest(".js-video-preview");
  if (!videoPreview) return;

  const video = videoPreview.closest(".js-video");
  const videoFrame = video?.querySelector<HTMLIFrameElement>(".js-video-frame");
  const src = videoFrame?.dataset["src"];
  if (!video || !videoFrame || !src) return;

  hideElement(videoPreview);
  videoFrame.src = src;
  showElement(videoFrame);
};
