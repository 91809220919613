import { loadScript } from "./utils";

const initMap = (mapElement: HTMLDivElement) => {
  const coordinates = { lat: 46.494225, lng: 11.360062 };
  const map = new google.maps.Map(mapElement, {
    center: coordinates,
    zoom: 16.25,
    disableDefaultUI: true,
    zoomControl: false,
    draggable: false,
    styles: [
      {
        featureType: "poi",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "transit.station",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
    ],
  });

  new google.maps.Marker({
    map,
    position: coordinates,
  });
};

let isGoogleMapsLoaded = false;

export const handleMapLoad = () => {
  if (isGoogleMapsLoaded) return;

  const mapElement =
    document.querySelector<HTMLDivElement>(".js-map-container");
  const mapsAPIKey = "AIzaSyDF5fVHyIi_xVcyi9sZiAESmdKrDh20VVM";
  const mapsURL = `https://maps.googleapis.com/maps/api/js?key=${mapsAPIKey}&callback=Function.prototype`;

  loadScript(mapsURL, () => {
    isGoogleMapsLoaded = true;
    mapElement && initMap(mapElement);
  });
};
