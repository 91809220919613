/**
 * Handles the mobile main menu state: open/closed
 */
export const handleMobileMainMenu = (mainMenuOuter: HTMLElement) => {
  const mainMenu = mainMenuOuter.querySelector<HTMLElement>(".js-main-menu");

  if (!mainMenu) return;

  const transitionClass = "main-menu-transitioning";
  mainMenu.addEventListener("transitionend", () =>
    mainMenu.classList.remove(transitionClass),
  );

  mainMenuOuter.addEventListener("click", (event) => {
    const target = <Element | null>event.target;
    const hiddenClass = "main-menu-hidden-on-narrow";
    const toggleButton = target?.closest(".js-main-menu-toggle-button");
    const link = target?.closest("a");
    // close if a link was selected
    if (toggleButton || link) {
      mainMenu.classList.toggle(transitionClass, true);
      mainMenu.classList.toggle(hiddenClass);
    }
  });
};

export const handleWideHeader = () => {
  const triggerElement = document.querySelector<HTMLElement>(
    ".js-fixed-menu-observer-trigger",
  );
  const pageHeader = document.querySelector<HTMLElement>(".js-page-header");
  const className = "page-header-narrow";
  if (!triggerElement || !pageHeader) return;

  const observer = new IntersectionObserver(([entry]) => {
    if (entry?.intersectionRatio === 1) {
      pageHeader.classList.remove(className);
      return;
    }
    pageHeader.classList.toggle("page-header-narrow");
  });
  observer.observe(triggerElement);
};

export const handleNarrowFixedHeader = (mainMenuOuter: HTMLElement) => {
  const fixedHeader = mainMenuOuter.querySelector(".js-narrow-fixed-header");

  const hideFixedHeader = (hide: boolean) =>
    fixedHeader?.classList.toggle("narrow-fixed-header-hidden", hide);

  let isMenuIntersecting = false;

  const observer = new IntersectionObserver(([entry]) => {
    isMenuIntersecting = entry?.isIntersecting ?? false;
    isMenuIntersecting && hideFixedHeader(true);
  });

  observer.observe(mainMenuOuter);

  onScrollDirectionChange(
    (direction) => !isMenuIntersecting && hideFixedHeader(direction === "down"),
  );
};

type ScrollDirection = "up" | "down";

const onScrollDirectionChange = (
  callback: (scrollDirection: ScrollDirection) => void,
) => {
  let lastScrollTop = 0;
  let lastScrollDirection: ScrollDirection | undefined = "down";

  document.addEventListener("scroll", () => {
    const scrollTop = window.scrollY;

    if (scrollTop > lastScrollTop) {
      lastScrollDirection === "up" && callback("down");
      lastScrollDirection = "down";
    }

    if (scrollTop < lastScrollTop) {
      lastScrollDirection === "down" && callback("up");
      lastScrollDirection = "up";
    }

    lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
  });
};
