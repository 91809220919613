import { useEffect, useRef, useState } from "react";

interface Props {
  baseUrl: string;
  errorMsg: string;
  buttonText: string;
}

const Podcasts = ({ baseUrl, errorMsg, buttonText }: Props) => {
  const limit = 3;
  const [page, setPage] = useState(0);
  const [data, setData] = useState<{
    total: number;
    podcasts: { url: string }[];
  }>({ total: 0, podcasts: [] });
  const [error, setError] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("page", String(page));
    searchParams.set("limit", String(limit));

    fetch(`${baseUrl}/podcasts-data?${searchParams.toString()}`)
      .then((res) => res.json())
      .then((result) => {
        setData(
          data.podcasts.length
            ? {
                ...data,
                podcasts: [...data.podcasts, ...result.podcasts],
              }
            : result,
        );
      })
      .catch(() => setError(true));
  }, [page]);

  return (
    <>
      <>
        {error && <div>{errorMsg}</div>}
        {data.podcasts.map((p, i) => (
          <Podcast url={p.url} key={i} />
        ))}
      </>
      {page * limit + limit < data.total && (
        <button
          className="bare-link podcasts-more"
          onClick={() => setPage(page + 1)}
        >
          {buttonText}
        </button>
      )}
    </>
  );
};

interface PodcastProps {
  url: string;
}

const Podcast = ({ url }: PodcastProps) => {
  const scriptRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const script = document.createElement("script");

    script.src =
      "https://player.podigee-cdn.net/podcast-player/javascripts/podigee-podcast-player.js";
    script.dataset["configuration"] = url;
    script.classList.add("podigee-podcast-player");

    scriptRef.current?.appendChild(script);

    return () => {
      scriptRef.current?.removeChild(script);
    };
  }, [url]);

  return <div className="podcast" ref={scriptRef}></div>;
};

export default Podcasts;
